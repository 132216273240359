import service from "../services/profile.service.js";

export const profile = {
  namespaced: true,
  state: {
    me: null,
    profile_image: null,
  },
  getters: {
    me: (state) => state.me,  // Keep the 'me' getter to access user profile
    profileImage: (state) => state.profile_image,
    role: (state) => (state.me ? state.me.role : null),
  },
  actions: {
    async me({ commit }) {  // Renamed back to 'me' for dispatching as 'profile/me'
      const data = await service.getMe();
      console.log('Profile data fetched:', data);
      commit('SET_PROFILE', data);
    },
    async updateProfile({ commit }, updateUser) {
      const data = await service.updateProfile(updateUser);
      console.log('Profile updated:', data);
      commit('SET_PROFILE', data);
    },
    async uploadProfileImage({ commit }, file) {
      try {
        const imageUrl = await service.uploadProfileImage(file);
        console.log('Profile image updated:', imageUrl);
    
        // Append a timestamp to bust the cache
        const cacheBustedImageUrl = `${imageUrl}?timestamp=${new Date().getTime()}`;
    
        commit('SET_PROFILE_IMAGE', cacheBustedImageUrl);
    
        // Introduce a slight delay before refreshing the page (optional)
        setTimeout(() => {
          window.location.reload();
        }, 500); // 500ms delay
      } catch (error) {
        console.error('Error uploading profile image:', error);
      }
    },
    
    
  },
  mutations: {
    SET_PROFILE(state, profile) {
      console.log('Setting profile data:', profile);
      state.me = profile;
      state.profile_image = profile.profile_image || null;
    },
    SET_PROFILE_IMAGE(state, imageUrl) {
      console.log('Setting profile image:', imageUrl);
      state.profile_image = imageUrl;
    },
  }
};
